import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  ConditionAcceptanceCheckboxStyle,
  ConditionAcceptanceStyle,
  markdownStyle,
  paragraph,
} from '../index.module.scss';
import ImpactAlert from './ConditionsKamping/components/ImpactAlert';

const ConditionAcceptance = ({
  markdownSource,
  checked,
  changeChecked,
  subtitle,
  alerts,
  "data-cy": dataCy
}) => (
  <>
    {(alerts && alerts.length > 0) && <ImpactAlert alerts={alerts} />}
    <div className={ConditionAcceptanceStyle}>
    <Checkbox
      className={ConditionAcceptanceCheckboxStyle}
      checked={checked}
      onChange={() => changeChecked(!checked)}
      data-cy={dataCy}
    />
    
    <div className={markdownStyle}>
      {markdownSource && <ReactMarkdown source={markdownSource} />}
      {subtitle && <p className={paragraph}>{subtitle}</p>}
    </div>
  </div>
  </>
  
);

ConditionAcceptance.defaultProps = {
  markdownSource: null,
  checked: false,
  changeChecked: () => {},
  subtitle: null,
  "data-cy": undefined,
  alerts: [],
};

ConditionAcceptance.propTypes = {
  markdownSource: PropTypes.string,
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
  subtitle: PropTypes.string,
  "data-cy": PropTypes.string,
  alerts: PropTypes.arrayOf(),
};

export default ConditionAcceptance;
