import React from "react";
import moment from "moment";
import { useIntl } from 'react-intl';
import { ReactComponent as DangerIcon } from "../../../../../../../../assets/icons/danger-alert.svg";
import { reservationAlert, reservationTitle, reservationList, reservationContent, reservationIcon } from "./index.module.scss";

const ImpactAlert = (alerts) => { 
  const { alerts: impacts } = alerts;
  const intl = useIntl();

  return (
    <div className={reservationAlert}>
      <DangerIcon className={reservationIcon} />
      <div className={reservationContent}>
        <p className={reservationTitle}>
          {intl.formatMessage({ id: 'Impact.Title' })}
        </p>
        <ul className={reservationList}>
          {impacts.map((alert) => (
            <li>
              {intl.formatMessage({ id: 'Impact.Dates' }, {fromDay: moment(alert.start).format("DD MMMM"), toDay: moment(alert.end).format("DD MMMM")} )}: &nbsp;
               {alert.notification}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ImpactAlert;