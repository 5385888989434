import PropTypes from 'prop-types';
import React from "react";
import { useIntl } from 'react-intl';
import ConditionAcceptance from '../../../ConditionAcceptance';

const ImpactConditionAcceptance = ({ checked, changeChecked, alerts }) => {
  
  const intl = useIntl();
  return(
    <ConditionAcceptance
      alerts={alerts}
      markdownSource={intl.formatMessage({ id: 'Resume.impacts' })}
      checked={checked}
      changeChecked={changeChecked}
      data-cy="checkout-step2-party"
    />
  )};

ImpactConditionAcceptance.defaultProps = {
  checked: false,
  changeChecked: () => {},
  alerts: []
};

ImpactConditionAcceptance.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
  alerts: PropTypes.arrayOf(),
};

export default ImpactConditionAcceptance;
